import Vue from 'vue'
import Sandra from './components/Sandra.vue'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(Sandra)
}).$mount('#app')
