<template>
    <v-container>
        <div class="page-header sbanner">
            <h2 class="text-center">In Memory of Sandra Carlock</h2>
            <h2 class="text-center">Nov 5, 1944 - Sep 8, 2021</h2>
        </div>
        <v-row>
            <v-col cols="6">
                <v-img max-height="300" contain src="images/sandra.jpg"></v-img>
            </v-col>
            <v-col cols="6">
                <div  class="mt-12">
                    <p>The world lost an outstanding artists on September 8th, 2021.
                        Read more about her and her life's work at the obituaries about her at
                        <a href="https://obituaries.mcalesternews.com/obituary/sandra-carlock-1083249064">McAlester News-Capital</a>
                        and
                        <a href="https://www.inquirer.com/obituaries/sandra-carlock-obituary-settlement-piano-20211008.html">The Philadelphia Inquirer</a>.
                    Upon learning her cancer prognosis, Sandra's biggest wish was
                    that people take moment to reflect on all of her life's
                    accomplishments in both her music and her photography. To
                    honor her memory, please explore the tabs below that describe
                    each of her biggest passions.</p>
                </div>
            </v-col>
        </v-row>
        <v-tabs v-model="tab" width="100%" class="mb-2 pb-2">
            <v-tab key="Musician">Muscian
            </v-tab>
            <v-tab key="Teacher">Teacher
            </v-tab>
            <v-tab key="Photographer">Photographer
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="mt-2">
            <v-tab-item key="Muscian">
                <v-row>
                    <v-col cols="8">
                        <div class="mt-3">
                            <p class="mt-3 pt-3">Sandra Carlock was an accomplished Pianist and proudly published multiple albums with the help of <a href="https://www.somm-recordings.com/?s=sandra+carlock/">Somm Recordings</a>.  In the last few years she worked very closely with Guilaume Combet to produce two award winning violin and piano duet albums.  She was proud that her <a href="https://images.sandracarlock.com/">photography</a> could be featured as covers on these two albums as well.</p>
                        </div>
                        
                        <div class="mt-3 space-top">
                            <p>For further information about Sandra's accomplishments as a muscian, please see her <a href="https://piano.sandracarlock.com/">website devoted to her musical career</a>.</p>
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <v-img src="https://piano.sandracarlock.com/images/littlesandra.png" contain></v-img>
                        <p><center><em>Sandra began her musical career very early</em></center></p>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item key="Teacher">

                <p>Sandra proudly taught at the <a href="https://settlementmusic.org/people/sandra-carlock/">Settlement Music School</a>.  She loved teaching and was proud of her young, talented students.  Some of her students can be seen performing on her <a href="https://www.youtube.com/c/SandraCarlock">YouTube</a> channel.</p>
                <div class="space-top"></div>
                <v-row class="mt-3 space-top">
                    <v-col cols="4">
                        <a href="/images/sandra_teacher_2.jpg">
                            <v-img contain max-height="200" src="/images/sandra_teacher_2.jpg">
                            </v-img>
                        </a>
                    </v-col>
                    <v-col cols="4">
                        <a href="/images/sandra_teacher_1.jpg">
                            <v-img contain max-height="200" src="/images/sandra_teacher_1.jpg">
                            </v-img>
                        </a>
                    </v-col>
                    <v-col cols="4">
                        <a href="/images/sandra_teacher_3.jpg">
                            <v-img contain max-height="200" src="/images/sandra_teacher_3.jpg">
                            </v-img>
                        </a>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item key="Photographer">
                <v-row>
                    <v-col cols="3">
                        <v-img
                            src="/images/sandra-photographer.jpg"
                            max-height="300" contain>
                        </v-img>
                    </v-col>
                    <v-col cols="5">
                        <p>Sanda's vision as a photograher was keenly inspired by the artistic view of the world she saw in everything.  She had a unique view of the world unlike anyone else, combining her love for both arts together.  She was a proud original member of the <a href="https://thearcanum.com/">The Arcanum</a> as a student of <a href="https://karenhutton.com/">Karen Hutton</a>, <a href="https://ronclifford.com/">Ron Clifford</a> and <a href="https://photos.captured.earth/">Wes Hardaker</a>.</p>
                        <p class="space-top">
                            Toward the end of her life, she started
                            documenting the way she saw music visually
                            in the world around her, revealing her
                            ability to see musical compositions
                            everywhere in nature.  Please honor her by
                            reading her essay released posthumously with her permisson:<p>
                            <p class="space-top">
                            <a href="/documents/sandra-carlock-music-and-photography.pdf">
                            "Music and Photography - A discussion of the elements common to both"</a></p>
                            <p class="space-top">
                                Sanda spent the last few years distilling her many years of photography down to her favorites.  She would be honored if you would visit her <a href="https://images.sandracarlock.com">Photography website</a> to view her lifes' work as an accomplished photographer.
                            </p>
                    </v-col>
                    <v-col cols="4">
                        <v-img
                            src="/images/music-photography.png"
                            max-height="300" contain>
                        </v-img>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>
<script>
 var sandra_data = {tab: 0}

 export default {
     name: 'Sandra',
     data: function() { return sandra_data; }
 }
</script>
<style>
 .sbanner {
     text-align: center;
     font-size: 1.2em;
     margin-bottom: 1em;
 }
 .v-window-item {
     margin-top: 1em;
 }
 .space-top {
     margin-top: 1em;
 }
</style>
